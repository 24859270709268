import React from 'react'
import {getFilters, getPath, setFilters} from '../utils/browserUtils';
import mainRouter from '../router/main/index';
import {Spin} from "antd";
import "../assets/css/app.css"
import UserApi from "../api/userApi";
// paratest
async function checkToken() {
    const token = getFilters("token");
    if (token) {
        return await UserApi.getUserInfo().then(rsp => {
            let isToken = false
            if(!rsp.error){
                setFilters("user",rsp.result)
                isToken =  true;
            }
            return isToken;
        })
    } else {
        return false;
    }
}

function showPage(token){
    let href = getPath()
    if(href === "/login"){
        if(token){
            return mainRouter[1].component
        }
        return mainRouter[0].component
    }else {
        if(token){
            return mainRouter[2].component
        }else {
            return mainRouter[0].component
        }
    }
}

let load = (<div className={'init-show'}><Spin tip="Loading..." size="large"><div className="content" /></Spin></div>);

class App extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoad:true,
            token:false,
        };
        this.check = this.check.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async check() {
        const flag = await checkToken();
        this.setState({ isLoad: false });
        this.setState({ token: flag });
    }
    render() {
        return (
            <div>
                {this.state.isLoad?load:(<div className={'app-show'}>{showPage(this.state.token)}</div>)}
            </div>
        )
    }
}

export default App;
